function parseDate(input, format) {
  format = format || 'yyyy-mm-dd'; // default format
  var parts = input.match(/(\d+)/g),
      i = 0, fmt = {};
  // extract date-part indexes from the format
  format.replace(/(yyyy|dd|mm)/g, function(part) { fmt[part] = i++; });

  return new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']]);
}

$(document).ready(function(){

    var idioma = $('html').attr('lang');

    /*
     * Eliminar filtres llista resultats
     */
    $('.removeFilter').click(function(e){
        e.preventDefault();
        var field = $(this).data('filter');
        var value = $(this).data('value');
        var filterForm = $('.filterForm');
        if (field == 'paraulaClau') {
            filterForm.find("input[name*='[paraulaClau]']").val('');
        } else if (field == 'tipusAnunciant') {
            filterForm.find("input[name*='[tipologiaAnunciant]']").val(filterForm.find("input[name*='[tipologiaAnunciantBase]']").val());
        } else if (field == 'tipusAnunci') {
            filterForm.find("input[name*='[tipusAnunci]']").val(filterForm.find("input[name*='[tipusAnunciBase]']").val());
        } else if (field == 'ods') {
            filterForm.find("input[name*='[ods]']").each(function(){
                if ($(this).val() == value) {
                    $(this).remove();
                }
            });
            /*filterForm.find("input[name*='[odsBase]']").each(function(){
                $(this).before('<input type="hidden" name="bopb_cerca[ods][]" value="'+($(this).val())+'">')
            });*/


        }
        filterForm.submit();
        return false;
    });


    /*
     * Copiar enllaç permanent
     */
    var a = document.querySelectorAll("a.copyToClipBoard");

    for (var i = 0; i < a.length; i++) {
      a[i].addEventListener("click", function(e) {
        e.preventDefault();
        copyToClipboard(this);
        return false;
      });
    }

    function copyToClipboard(anchor) {
      try {
        var input = document.createElement('input');
        input.type = "text";
        input.setAttribute("value", anchor.getAttribute('href'));
        input.id = "test";
        document.body.appendChild(input);

        var inputEl = document.getElementById("test");
        inputEl.focus();
        inputEl.setSelectionRange(0, inputEl.value.length);

        const successful = document.execCommand('copy');
        document.body.removeChild(inputEl);

        const message = successful ? anchor.getAttribute('data-message-ok') : anchor.getAttribute('data-message-error');
        alert(message);
      } catch (err) {
        const message = anchor.getAttribute('data-message-error');
        alert(message);
      }
    }


    // Datepicker
    function initInputFestiusDatepicker(selector, params)
    {
        var monthChanged = false;
        var component = $(selector).datepicker(params)
        .on('show', function(e){
            if (!monthChanged) {
                var viewDate = new Date(e.timeStamp);
                if ($(this).val() != '') {
                    viewDate = parseDate($(this).val(), 'dd-mm-yyyy');
                }
                $.ajax({
                    url : '/calendari/festius/' + viewDate.getFullYear() + '/' + (viewDate.getMonth()+1),
                    type: 'get',
                    beforeSend: function() {
                        $('.datepicker.datepicker-dropdown .datepicker-days').prepend('<span class="loading"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></span>');
                    },
                    success: function(data) {
                        $(selector).data("datepicker").setDatesDisabled(data);
                        $('.datepicker.datepicker-dropdown .datepicker-days .loading').fadeOut('400', function(){
                            $('.datepicker.datepicker-dropdown .datepicker-days .loading').remove();
                        });
                    }
                });
            }
        })
        .on('changeMonth', function(e){
            var viewDate = e.date;
            $.ajax({
                url : '/calendari/festius/' + viewDate.getFullYear() + '/' + (viewDate.getMonth()+1),
                type: 'get',
                beforeSend: function() {
                    $('.datepicker.datepicker-dropdown .datepicker-days').prepend('<span class="loading"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></span>');
                },
                success: function(data) {
                    $(selector).data("datepicker").setDatesDisabled(data);
                    $('.datepicker.datepicker-dropdown .datepicker-days .loading').fadeOut('400', function(){
                        $('.datepicker.datepicker-dropdown .datepicker-days .loading').remove();
                    });
                }
            });
            monthChanged = true;
        });

        return component;
    }
    initInputFestiusDatepicker('.bopcalendar', {
        format: "dd-mm-yyyy",
        language: idioma,
        weekStart: 1,
        useCurrent: false,
        updateViewDate: false,
        autoclose: true,
        endDate: "0d"
    });

    var calendarStart = initInputFestiusDatepicker('.bopcalendar-start', {
        format: "dd-mm-yyyy",
        language: idioma,
        weekStart: 1,
        useCurrent: true,
        updateViewDate: false,
        autoclose: true,
        endDate: "0d",
        setDate: new Date($('.bopcalendar-start').val())
    });

    var calendarEnd = initInputFestiusDatepicker('.bopcalendar-end', {
        format: "dd-mm-yyyy",
        language: idioma,
        weekStart: 1,
        useCurrent: true,
        updateViewDate: false,
        autoclose: true,
        endDate: "0d",
        setDate: new Date($('.bopcalendar-end').val())
    });

    function addMonths(date, months) {
        var d = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() != d) {
          date.setDate(0);
        }
        return date;
    }
    function toDate(dateStr) {
      var parts = dateStr.split("-")
      return new Date(parts[2], parts[1] - 1, parts[0])
    }
    function setEndDateRange(date) {
        minDate = new Date(date);
        maxDate = addMonths(new Date(date), 3)
        endDate = toDate($('.bopcalendar-end').val());
        $('.bopcalendar-end').datepicker('setStartDate', minDate);
        $('.bopcalendar-end').datepicker('setEndDate', maxDate);
        if (endDate > maxDate) {
            $('.bopcalendar-end').datepicker('setDate', maxDate);
        }
    }
    // calendarStart.on("changeDate", function (e) {
    // 	setEndDateRange(toDate($('.bopcalendar-start').val()));
    // });
    // calendarEnd.on("changeDate", function (e) {
    // 	minDate = addMonths(new Date(e.date), -3);
    // 	maxDate = new Date(e.date);
    // 	startDate = toDate($('.bopcalendar-start').val());
    // 	$('.bopcalendar-start').datepicker('setStartDate', minDate);
    // 	$('.bopcalendar-start').datepicker('setEndDate', maxDate);
    // 	if (startDate < minDate) {
    // 		$('.bopcalendar-start').datepicker('setDate', minDate);
    // 	}
    // });

    // if ($('.bopcalendar-start').length && $('.bopcalendar-end').length) {
    // 	if ($('.bopcalendar-start').val() != '') {
    // 		setEndDateRange(toDate($('.bopcalendar-start').val()));
    // 	}
    // }

    $('.bopcalendar--wrapper').on('changeDate', function() {
        var date = $(this).datepicker('getFormattedDate');
        $('#cerca_per_dia_data').val(date);
        $(this).closest('form').submit();
    });
    $('.bopcalendar--wrapper').trigger('show');

    /*
     * Dependències entre datepickers inici i fi
     */
    $('[data-dependent="inici"]').on('changeDate', function(selected) {
        var minDate = new Date(selected.date.valueOf());
        $('[data-dependent="fi"]').data("datepicker").setStartDate(minDate);
    });

    $('[data-dependent="fi"]').on('changeDate', function(selected) {
        var maxDate = new Date(selected.date.valueOf());
        $('[data-dependent="inici"]').data("datepicker").setEndDate(maxDate);
    });

    // /*
    //  * Dependent selects
    //  */
    // function updateDependentSelectByParentName(inputNamePrefix, parentName, parentValue)
    // {

    // 	if(parentValue !== '' && $("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "'] option[data-parent-id='"+parentValue+"']").length) {
    // 		$("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "'] option").css('display', 'none');
    // 		$("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "'] option[data-parent-id='"+parentValue+"']").css('display', 'inline-block');
    // 		$("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").prop('disabled', false);
    //     	$("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").removeAttr('disabled');
    // 	} else {
    // 		$("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").prop('disabled', 'disabled');
    //     	$("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").attr('disabled', 'disabled');
    // 	}
    // 	$("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").val('');
    // 	$("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").trigger('change');
    // }


    // $(document).on('change', 'select.dependentSelect', function(e){
    // 	var selectInput = $(this);

    // 	var inputNamePrefix = $(this).attr('name');
    // 	var patt = new RegExp("(\[(tipologiaAnunciant|tipusAnunci)[0-9]+\])$");
    // 	if (patt.test(inputNamePrefix)) {
    // 		var res = patt.exec(inputNamePrefix);
    // 		inputNamePrefix = inputNamePrefix.replace(res[0],'');
    // 	}
    // 	updateDependentSelectByParentName(inputNamePrefix, selectInput.data('name'), selectInput.val());
    // });

    /*
     * Dependent selects
     */
    function updateDependentSelectByParentName(inputNamePrefix, parentName, parentValue, clear)
    {
        var select = $("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']");

        if(!(parentValue !== '' && $("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "'] option[data-parent-id='"+parentValue+"']").length)) {
            select.empty();
            $.each(dependentSelects[select.attr('name')], function( index, value ) {
                select.append(value);
            });
        }

        if(parentValue !== '' && $("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "'] option[data-parent-id='"+parentValue+"']").length) {
            $("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "'] option[data-parent-id][data-parent-id!='"+parentValue+"']").remove();
            $("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").prop('disabled', false);
            $("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").removeAttr('disabled');
        } else {
            $("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").prop('disabled', 'disabled');
            $("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").attr('disabled', 'disabled');
        }
        if (clear) {
            $("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").val('');
        }
        $("select[name^='" + inputNamePrefix +"'][data-parent-name='" + parentName + "']").trigger('change');
    }

    var patt = new RegExp("(\[(tipologiaAnunciant|tipusAnunci)[0-9]+\])$");
    var dependentSelects = [];
    $('select.dependentSelect').each(function(e){
        var inputNamePrefix = $(this).attr('name');
        if (patt.test(inputNamePrefix)) {
            dependentSelects[inputNamePrefix] = [];
            $(this).find('option').each(function(e){
                dependentSelects[inputNamePrefix].push($(this).get(0).outerHTML);
            });
        }
    });

    $($('select.dependentSelect').get().reverse()).each(function(){
        actualitzarSelectsDependents($(this), false);
        if ($(this).attr("data-parent-name") !== undefined && $(this).val() == '') {
            $(this).prop('disabled', 'disabled');
        }
    })

    $(document).on('change', 'select.dependentSelect', function(e){
        actualitzarSelectsDependents($(this), true);
    });

    function actualitzarSelectsDependents(selectInput, clear) {
        var inputNamePrefix = selectInput.attr('name');
        if (patt.test(inputNamePrefix)) {
            var res = patt.exec(inputNamePrefix);
            inputNamePrefix = inputNamePrefix.replace(res[0],'');
        }
        updateDependentSelectByParentName(inputNamePrefix, selectInput.data('name'), selectInput.val(), clear);
    }


    /*
     * Formularis amb coleccions
     */
    var registerDsiListCounter = 0;
    $('.register-dsi-collection-widget').click(function (e) {
        var list = $($(this).attr('data-list-selector'));
        // Try to find the counter of the list or use the length of the list
        registerDsiListCounter = list.data('widget-counter') || list.children().length;

        // grab the prototype template
        var newWidget = list.attr('data-prototype');
        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your emails
        // end name attribute looks like name="contact[emails][2]"
        newWidget = newWidget.replace(/__name__/g, Date.now());
        // Increase the counter
        registerDsiListCounter++;
        // And store it, the length cannot be used if deleting widgets is allowed
        list.data('widget-counter', registerDsiListCounter);


        // create a new list element and add it to the list
//        var newElem = $(list.attr('data-widget-tags')).html(newWidget);
        var newElem = $(list.attr('data-widget-tags')).html('<div class="wrapper"><button class="close de	e btn btn-link text-decoration-none px-0">Eliminar aquest criteri de cerca</button>' + newWidget + '</div>');
        newElem.appendTo(list);
        if (registerDsiListCounter >= 6) {
            $('button.register-dsi-collection-widget').addClass('invisible')
        }
    });
    $(document).on('click', "#subscripcioCerca-fields-list .wrapper button.delete", function(e){
        e.preventDefault();
        $(this).closest('li').remove();
        registerDsiListCounter--;
        $("#subscripcioCerca-fields-list").data('widget-counter', registerDsiListCounter);
        if (registerDsiListCounter < 6) {
            $('button.register-dsi-collection-widget').removeClass('invisible');
        }
        return false;
    });


    /*
     * Formulari atenció al client contacte
     *
     */
    function switchContacteInput()
    {
        $('div.form-group.data-switcher:not([data-switch="' + $('select.switchContacte :selected').val() + '"])').hide();
        $('div.form-group.data-switcher:not([data-switch="' + $('select.switchContacte :selected').val() + '"]) input').removeAttr('required');
        $('div.form-group.data-switcher[data-switch="' + $('select.switchContacte :selected').val() + '"]').show();
        $('div.form-group.data-switcher[data-switch="' + $('select.switchContacte :selected').val() + '"] input').attr('required', 'required');
    }


    if ($('select.switchContacte').length) {
        $('select.switchContacte').on('change', switchContacteInput)
        switchContacteInput();
    }

    /*
     * Checkbox multilevel filter amb estat indeterminat
     */
//    $(document).on('change', '.hasChilds input[type="checkbox"]', function(e) {
//      var checked = $(this).prop("checked"),
//          container = $(this).closest('.hasChilds').next();
//      container.find('input[type="checkbox"]').prop({
//        indeterminate: false,
//        checked: checked
//      });
//      container.find('input[type="checkbox"]').each(function(){
//    	  checkDescendantsAccess($(this));
//      })
//
//    });
//
//    function checkCheckedSiblings(el) {
//        var parent = el.closest('ul.nav-filtres').prev('.hasChilds'),
//            all = true,
//            checked = parent.find('input[type="checkbox"]').prop("checked"),
//            countChecked = el.find('li input[type="checkbox"]:checked').length;
//        if (countChecked == el.find('li input[type="checkbox"]').length){
//          all = true;
//          checked = true;
//        } else if (countChecked == 0){
//          all = true;
//          checked = false;
//        } else {
//          all = false;
//          checked = false;
//        }
//        if (all && checked) {
//          parent.find('input[type="checkbox"]').prop({
//            indeterminate: false,
//            checked: checked
//          });
//        } else if (all && !checked) {
//          parent.find('input[type="checkbox"]').prop({
//            indeterminate: false,
//            checked: checked
//          });
//        } else {
//          parent.find('input[type="checkbox"]').prop({
//            indeterminate: true,
//            checked: false
//          });
//        }
//    }

    function checkDescendantsAccess(checkbox)
    {
        var nextPlusSign = $(checkbox).closest('.form-group').next('a.open-icon'),
            checkedInput = $(checkbox).prop("checked");
        if ($(nextPlusSign).length) {
            $(nextPlusSign).toggleClass('invisible', checkedInput);
        }

        var inputHiddenIdSelector = $('input[type="checkbox"][data-type="'+$(checkbox).data('type')+'"][data-parent="'+$(checkbox).attr('value')+'"]:checked');
        if ($(inputHiddenIdSelector).length) {

            if (checkedInput) {
                $(inputHiddenIdSelector).prop({checked: !checkedInput});
                $(inputHiddenIdSelector).removeAttr("checked");
            }
        }
    }
    $(document).on('change', '.nav-filtres input[type="checkbox"]', function(e) {
      var container = $(this).closest('.nav-filtres');
//      checkCheckedSiblings(container);
      checkDescendantsAccess($(this));
    });
//    if ($('ul.nav-filtres').length) {
//      $('ul.nav-filtres').each(function(){
//        checkCheckedSiblings($(this));
//      });
//    }

    /*
     * Ajax dels filtres de 3 nivell i endavant
     */
    $(document).on('click','.loadAjaxFilterUrl', function(e){
//    	e.preventDefault();
        var selector = '#filtre-' + $(this).data('type') + '-' + $(this).data('id');

        $('.overlay-menu').removeClass('overlay-menu-open');
        if ($(selector).length) {
            $(selector).addClass('overlay-menu-open');
        } else {
            $.ajax(
                $(this).data('url'),
                {
                    async: false,
                    dataType: 'html',
                    success: function(data){
                        $('.filterForm').append(data);
                    }
                }
            );
        }
        return true;
    });

    $(document).on('click', '.overlay-menu .situacio a', function(e){
        $(this).closest('.overlay-menu').removeClass('overlay-menu-open');
        return true;
    });

    /*
     * Eliminem els input checked dels filtre anunciant i tipus anunci quan hi
     * ha canvis en els subfiltres ajax
     */
    $(document).on('change', '.overlay-menu input[type="checkbox"]', function(e){
        var checkedInput = $(this).prop("checked"),
        checkboxType = $(this).data("type"),
        checkboxId = $(this).data("id");

        var inputHiddenIdSelector = $('.navegacio-interna input[type="checkbox"][data-type="'+checkboxType+'"][data-id="'+checkboxId+'"]');
        if ($(inputHiddenIdSelector).length) {
            $(inputHiddenIdSelector).prop({checked: checkedInput});
        }
        return true;
    });

    // Arreglar fletxes datepicker
    $( ".bopcalendar .prev, .bopcalendar .next" ).wrapInner( "<span class='sr-only'></span>");

    /*
     * Vex eliminar subscripcions
     */

    if(typeof vex != 'undefined') {
        vex.defaultOptions.className = "vex-theme-plain";
    }

    $(document).on('click', "a.confirmDeleteCerca", function(event) {
        event.preventDefault();
        var url = $(this).attr('href');
        var message = $(this).data('message');
        var labelOk = $(this).data('label-ok');
        var labelCancel = $(this).data('label-cancel');
        vex.dialog.confirm({
            message: message,
            buttons: [
                $.extend({}, vex.dialog.buttons.YES, {
                    text: labelOk
                }), $.extend({}, vex.dialog.buttons.NO, {
                    text: labelCancel
                })
            ],
            callback: function(value) {
                if (value) {
                    window.location = url
                }
            }
        });
    });

    $('[data-listcut]').each(function(){
        var list = $(this);
        list.find('li:nth-child(n+'+(list.data('listcut') + 1)+')').hide();
        if (list.find('li').length > list.data('listcut')) {
            var link = $('<a href="#">'+list.data('listcut-open')+'</a>');
            list.append(link);
            link.on('click', function(event){
                event.preventDefault();
                list.find('li').show();
                link.hide();
                link2.show();
            });
            var link2 = $('<a href="#">'+list.data('listcut-close')+'</a>').hide();
            list.append(link2);
            link2.on('click', function(event){
                event.preventDefault();
                list.find('li:nth-child(n+'+(list.data('listcut') + 1)+')').hide();
                link2.hide();
                link.show();
            });
        }
    });

});
