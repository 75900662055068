$(document).ready(function () {
  ("use strict");

  // Datepicker
  $('input.date').datepicker({
    format: "dd-mm-yyyy",
    language: "ca",
    weekStart: 1
  });

  // Tooltips
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })



  // ODS checkboxes: marcar els seleccionats
  $('.ods-selector input[type="checkbox"]:checked').each(function () {
    $(this).parent().addClass('border border-dark');
  });

  // ODS checkboxes: marcar els seleccionats
  $('.ods-selector input[type="checkbox"]').on('change', function () {
    if ($(this).prop('checked')) {
      $(this).parent().addClass('border border-dark');
    } else {
      $(this).parent().removeClass('border border-dark');
    }
  });

  // ODS checkboxes: marca els labels quan es navega per teclat
  $('.ods-selector input[type="checkbox"]').focus(function() {
      $(this).parent().addClass('focused-label');
  })
  $('.ods-selector input[type="checkbox"]').blur(function() {
    $(this).parent().removeClass('focused-label');
  });
});